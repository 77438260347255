<template>
    <div class="container min-height">
        <top active="2"></top>
        <img class="header-image" src="../../assets/image/1546.png" width="3840" alt="" style="width: 100%" />
        <div class="main">
            <div class="form-container">
                <el-form v-loading="loading" ref="dataForm" :model="formData" :rules="rules" :disabled="!editable" inline label-position="left" class="form-body">
                    <div class="area form-area info-area">
                        <div class="title">
                            <el-image :src="titleIcon" class="icon" />
                            <div class="text">作品信息</div>
                        </div>
                        <div :style="samplesTip" class="area-form">
                            <div class="inline">
                                <el-form-item prop="copyRightTypeNo" label="作品类别">
                                    <el-select v-model="formData.copyRightTypeNo" placeholder="请选择作品类别" @change="copyRightTypeChange(formData.copyRightTypeNo)">
                                        <el-option v-for="{ text, value } in copyRightTypeDict" :key="text" :label="text" :value="value" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="title" label="作品名称" class="title-field">
                                    <el-input v-model="formData.title" placeholder="请输入作品名称" minlength="1" maxlength="50" />
                                </el-form-item>
                            </div>
                            <el-form-item prop="createDate" label="创作/制作完成日期">
                                <el-date-picker v-model="formData.createDate" placeholder="请选择创作/制作完成日期" />
                            </el-form-item>
                            <el-form-item prop="creationTypeNo" label="作品创作性质">
                                <el-radio-group v-model="formData.creationTypeNo">
                                    <el-radio v-for="{ text, value } in creationTypeDict" :key="text" :label="value">{{ text }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item prop="rightWayNo" label="权利取得方式">
                                <el-radio-group v-model="formData.rightWayNo">
                                    <el-radio v-for="{ text, value } in rightWayDict" :key="text" :label="value">{{ text }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item prop="ownerShipWayNo" label="版权归属方式">
                                <el-radio-group v-model="formData.ownerShipWayNo">
                                    <el-radio v-for="{ text, value } in ownerShipWayDict" :key="text" :label="value">{{ text }}</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="needVestingYears" prop="vestingYears" label="归属年限">
                                <el-input v-model="formData.vestingYears">
                                    <template #append>年</template>
                                </el-input>
                            </el-form-item>
                            <div class="inline">
                                <el-form-item prop="isPublic" label="发表状态">
                                    <el-radio-group v-model="formData.isPublic">
                                        <el-radio v-for="{ text, value } in isPublicDict" :key="text" :label="value">{{ text }}</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item v-if="needFirstPublicDate" prop="firstPublicDate" label="首次发表时间">
                                    <el-date-picker v-model="formData.firstPublicDate" placeholder="请选择首次发表日期" />
                                </el-form-item>
                            </div>
                            <el-form-item prop="rightOptions" label="权利拥有状况" class="right-options-field">
                                <el-checkbox v-model="selectAllRightOptions" label="全部"></el-checkbox>
                                <el-checkbox-group v-model="formData.rightOptions">
                                    <el-checkbox v-for="{ text } in rightOptionDict" :key="text" :label="text"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                            <div class="inline attachments">
                                <el-form-item prop="samples" label="作品样本" class="samples">
                                    <el-upload :limit="1" :class="{ 'hide-uploader': formData.samples.length >= 1 }" :before-upload="beforeImageUpload" :on-success="onSampleSuccess" :on-remove="onSampleRemove" :file-list="formData.samples" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/4`" list-type="picture-card">
                                        <i class="el-icon-plus" />
                                    </el-upload>
                                </el-form-item>
                                <el-form-item prop="ancillaryMaterials" label="辅助资料" class="ancillary-materials">
                                    <el-upload ref="ancillaryMaterialsUploader" :limit="10" :class="{ 'hide-uploader': formData.ancillaryMaterials.length >= 10 }" :before-upload="beforeImageUpload" :on-success="onAncillaryMaterialsSuccess" :on-remove="onAncillaryMaterialsRemove" :file-list="formData.ancillaryMaterials" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/6`" list-type="picture-card" multiple>
                                        <i class="el-icon-plus" />
                                    </el-upload>
                                </el-form-item>
                            </div>
                            <div class="inline instruction experience" style="margin-top: 5rem">
                                <el-form-item prop="instruction" label="作品简介及创作" class="instruction">
                                    <el-input v-model="formData.instruction" type="textarea" rows="5" minlength="30" maxlength="2000" placeholder="根据作品样本内容进行描述介绍，并应当说明作品的独创性内容（比如：文字作品要求说明文章字数以及文章内容和含义，美术作品要求描述作品的主要元素及其构成方式，摄影作品要求说明具体摄影参数和摄影内容）" />
                                </el-form-item>
                                <el-form-item prop="experience" label="创作经过及意图">
                                    <el-input v-model="formData.experience" type="textarea" rows="5" minlength="30" maxlength="2000" placeholder="应当说明创作作品的缘由、经过及作品的具体运用范围等。（如果运用公用领域的基本元素或其他著作权人已有作品衍生创作的作品，应说明情况，填写使用的原作品名称、著作权人和作品出处）" />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div class="area table-area owner-area">
                        <div class="title">
                            <el-image :src="titleIcon" class="icon" />
                            <div class="text">著作人信息</div>
                        </div>
                        <el-table v-loading="ownerListLoading" ref="ownerTable" :data="ownerList" :row-key="(row) => row.id" border max-height="260" @select="ownerSelect" @selection-change="ownerSelectChange">
                            <el-table-column :reserve-selection="true" type="selection" />
                            <el-table-column prop="ownerTypeName" label="类别" />
                            <el-table-column prop="ownerName" label="姓名/名称" />
                            <el-table-column prop="mobile" label="手机号" />
                            <el-table-column prop="cardNo" label="证件号" />
                            <el-table-column prop="signature" label="笔名" />
                            <el-table-column prop="regionsNowName" label="户籍所在地" />
                            <el-table-column fixed="right" label="操作">
                                <template slot-scope="scope">
                                    <el-button class="edit-button" type="text" size="small" @click="ownerUpdateWindowOpen(scope.row.id)">编辑</el-button>
                                    <el-button class="delete-button" type="text" size="small" @click="deleteOwner(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="add-new">
                            <el-button type="text" @click="ownerCreationWindowOpen">
                                <div class="button-content">
                                    <el-image :src="addNewMemberIcon" />
                                    <span>添加新的著作权人</span>
                                </div>
                            </el-button>
                        </div>
                    </div>
                    <div v-if="!notNeedAuthorInfo" class="area table-area author-area">
                        <div class="title">
                            <el-image :src="titleIcon" class="icon" />
                            <div class="text">作者信息</div>
                        </div>
                        <el-table v-loading="authorListLoading" ref="authorTable" :data="authorList" :row-key="(row) => row.id" border max-height="260" @selection-change="authorSelectChange">
                            <el-table-column :reserve-selection="true" type="selection" />
                            <el-table-column prop="ownerTypeName" label="类别" />
                            <el-table-column prop="ownerName" label="姓名" />
                            <el-table-column prop="mobile" label="手机号" />
                            <el-table-column prop="cardNo" label="证件号" />
                            <el-table-column prop="signature" label="笔名" />
                            <el-table-column label="具名">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.isPrint" active-text="是" inactive-text="否"></el-switch>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作">
                                <template slot-scope="scope">
                                    <el-button class="edit-button" type="text" size="small" @click="authorUpdateWindowOpen(scope.row.id)">编辑</el-button>
                                    <el-button class="delete-button" type="text" size="small" @click="deleteAuthor(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="add-new">
                            <el-button type="text" @click="authorCreationWindowOpen">
                                <div class="button-content">
                                    <el-image :src="addNewMemberIcon" />
                                    <span>添加新的作者</span>
                                </div>
                            </el-button>
                        </div>
                    </div>
                    <div class="area form-area submission-area">
                        <div class="title">
                            <el-image :src="titleIcon" class="icon" />
                            <div class="text">申请人信息</div>
                        </div>
                        <div class="area-form">
                            <div class="inline">
                                <el-form-item prop="name" label="姓名或单位名称">
                                    <el-input v-model="formData.name" placeholder="请输入姓名或单位名称" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="phone" label="联系方式">
                                    <el-input v-model="formData.phone" placeholder="请输入联系方式" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                            <div class="inline">
                                <el-form-item prop="address" label="详细地址">
                                    <el-input v-model="formData.address" placeholder="请输入详细地址" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="mailer" label="联系人">
                                    <el-input v-model="formData.mailer" placeholder="请输入联系人" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                            <div class="inline">
                                <el-form-item prop="mobile" label="联系人联系方式">
                                    <el-input v-model="formData.mobile" placeholder="请输入联系人联系方式" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="email" label="联系人邮箱">
                                    <el-input v-model="formData.email" placeholder="请输入联系人邮箱" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div v-if="applicationFormFlag" class="area form-area submission-area">
                        <div class="title">
                            <el-image :src="titleIcon" class="icon" />
                            <div class="text">报名表信息</div>
                        </div>
                        <div class="area-form">
                            <div class="inline">
                                <el-form-item prop="signName" label="姓名">
                                    <el-input v-model="formData.signName" placeholder="请输入姓名" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="idcard" label="身份证号">
                                    <el-input v-model="formData.idcard" placeholder="请输入身份证号" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                            <div class="inline">
                                <el-form-item prop="company" label="单位">
                                    <el-input v-model="formData.company" placeholder="请输入单位" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="phone" label="手机号">
                                    <el-input v-model="formData.signPhone" placeholder="请输入手机号" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                            <div class="inline">
                                <el-form-item prop="openingBank" label="银行开户行">
                                    <el-input v-model="formData.openingBank" placeholder="请输入银行开户行" maxlength="50"></el-input>
                                </el-form-item>
                                <el-form-item prop="bankAccount" label="银行账号">
                                    <el-input v-model="formData.bankAccount" placeholder="请输入银行账号" maxlength="50"></el-input>
                                </el-form-item>
                            </div>
                            <div class="inline">
                                <el-form-item prop="accountName" label="银行账号姓名">
                                    <el-input v-model="formData.accountName" placeholder="请输入银行账号姓名" maxlength="50"></el-input>
                                </el-form-item>

                                <el-form-item prop="signType" label="参赛类别">
                                    <el-select v-model="formData.signType" placeholder="请选择参赛类别">
                                        <el-option v-for="{ text, value } in signTypeDict" :key="text" :label="text" :value="value" />
                                    </el-select>
                                </el-form-item>

                                <!-- <el-form-item prop="signType" label="参赛类别">
                                    <el-input v-model="applicationForm.signType" placeholder="请输入参赛类别" maxlength="50"></el-input>
                                </el-form-item> -->
                            </div>
                        </div>
                    </div>
                    <div class="area button-area">
                        <div class="button-container">
                            <el-button type="primary" plain class="save" @click="handleSave">暂存</el-button>
                            <el-button type="primary" class="sign" @click="handleCommit">提交</el-button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <p class="sign-off textAlign-c grey line-height2 margin-b">
            中共瑞安市委宣传部（瑞安市新闻出版局）©版权所有<br />
            瑞安市数据管理发展有限公司 技术支持
        </p>
        <el-dialog :visible.sync="ownerFormShow" class="edit-window owner-window">
            <div class="title">
                <el-image :src="titleIcon" class="left-icon icon" />
                <div class="text">添加新的著作权人</div>
                <el-image :src="titleIcon" class="right-icon icon" />
            </div>
            <el-form ref="ownerEditingForm" :model="ownerEditingInfo" :rules="ownerFormRule" inline label-position="left">
                <div class="item-line">
                    <el-form-item prop="ownerTypeNo" label="类别">
                        <el-select v-model="ownerEditingInfo.ownerTypeNo" placeholder="请选择类别">
                            <el-option v-for="{ text, value } in ownerTypeDict" :key="text" :label="text" :value="value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="ownerFormConfig.ownerNameLabel" prop="ownerName">
                        <el-input v-model="ownerEditingInfo.ownerName" :placeholder="`请输入${ownerFormConfig.ownerNameLabel}`" minlength="1" maxlength="50" />
                    </el-form-item>
                </div>
                <div class="item-line">
                    <el-form-item :label="ownerFormConfig.ownerCardNoLabel" prop="cardNo">
                        <el-input v-model="ownerEditingInfo.cardNo" :placeholder="`请输入${ownerFormConfig.ownerCardNoLabel}`" minlength="1" maxlength="50" />
                    </el-form-item>
                    <el-form-item prop="mobile" label="手机号">
                        <el-input v-model="ownerEditingInfo.mobile" placeholder="请输入手机号" minlength="1" />
                    </el-form-item>
                </div>
                <div class="item-line">
                    <el-form-item prop="regionsNowNoList" label="户籍所在地">
                        <el-cascader v-model="ownerEditingInfo.regionsNowNoList" :props="areaOptions" placeholder="请选择户籍所在地" />
                    </el-form-item>
                    <el-form-item prop="signature" label="笔名">
                        <el-input v-model="ownerEditingInfo.signature" placeholder="请输入笔名" minlength="1" maxlength="50" />
                    </el-form-item>
                </div>
                <div :class="{ 'is-required': true }" class="item-line el-form-item copyright-files">
                    <div class="el-form-item__label">{{ ownerFormConfig.copyrightFilesLabel }}</div>
                    <div class="el-form-item__content">
                        <template v-if="ownerNeedIdCard">
                            <div>
                                <el-upload :on-remove="onCardAvatarRemove" :before-upload="beforeAvatarUpload" :on-success="onCardAvatarSuccess" :file-list="ownerEditingInfo.idCardAvatar" :class="{ 'hide-upload': ownerEditingInfo.idCardAvatar.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">身份证人像面</div>
                            </div>
                            <div>
                                <el-upload :on-remove="onCardBackRemove" :before-upload="beforeAvatarUpload" :on-success="onCardBackSuccess" :file-list="ownerEditingInfo.idCardBack" :class="{ 'hide-upload': ownerEditingInfo.idCardBack.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">身份证国徽面</div>
                            </div>
                        </template>
                        <template v-if="ownerNeedLicense">
                            <div>
                                <el-upload :on-remove="onLicenseRemove" :before-upload="beforeAvatarUpload" :on-success="onLicenseSuccess" :file-list="ownerEditingInfo.license" :class="{ 'hide-upload': ownerEditingInfo.license.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">营业执照及有关证明</div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="button-area">
                    <div class="button-container">
                        <el-button class="cancel-button button" plain @click="ownerFormShow = false">取消</el-button>
                        <el-button class="save-button button" @click="ownerEditingSave">保存</el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
        <el-dialog :visible.sync="authorFormShow" class="edit-window author-window">
            <div class="title">
                <el-image :src="titleIcon" class="left-icon icon" />
                <div class="text">添加新的作者</div>
                <el-image :src="titleIcon" class="right-icon icon" />
            </div>
            <el-form ref="authorEditingForm" :model="authorEditingInfo" :rules="authorFormRule" inline label-position="left">
                <div class="item-line">
                    <el-form-item prop="ownerTypeNo" label="类别">
                        <el-select v-model="authorEditingInfo.ownerTypeNo" placeholder="请选择类别">
                            <el-option v-for="{ text, value } in ownerTypeDict" :key="text" :label="text" :value="value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="authorFormConfig.authorNameLabel" prop="ownerName">
                        <el-input v-model="authorEditingInfo.ownerName" :placeholder="`请输入${authorFormConfig.authorNameLabel}`" minlength="1" maxlength="50" />
                    </el-form-item>
                </div>
                <div class="item-line">
                    <el-form-item :label="authorFormConfig.authorCardNoLabel" prop="cardNo">
                        <el-input v-model="authorEditingInfo.cardNo" :placeholder="`请输入${authorFormConfig.authorCardNoLabel}`" minlength="1" maxlength="50" />
                    </el-form-item>
                    <el-form-item prop="mobile" label="手机号">
                        <el-input v-model="authorEditingInfo.mobile" placeholder="请输入手机号" minlength="1" />
                    </el-form-item>
                </div>
                <div class="item-line">
                    <el-form-item prop="signature" label="笔名">
                        <el-input v-model="authorEditingInfo.signature" placeholder="请输入笔名" minlength="1" maxlength="50" />
                    </el-form-item>
                </div>
                <div :class="{ 'is-required': true }" class="item-line el-form-item copyright-files">
                    <div class="el-form-item__label">{{ authorFormConfig.copyrightFilesLabel }}</div>
                    <div class="el-form-item__content">
                        <template v-if="authorNeedIdCard">
                            <div>
                                <el-upload :on-remove="onCardAvatarRemove" :before-upload="beforeAvatarUpload" :on-success="onCardAvatarSuccess" :file-list="authorEditingInfo.idCardAvatar" :class="{ 'hide-upload': authorEditingInfo.idCardAvatar.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">身份证人像面</div>
                            </div>
                            <div>
                                <el-upload :on-remove="onCardBackRemove" :before-upload="beforeAvatarUpload" :on-success="onCardBackSuccess" :file-list="authorEditingInfo.idCardBack" :class="{ 'hide-upload': authorEditingInfo.idCardBack.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">身份证国徽面</div>
                            </div>
                        </template>
                        <template v-if="authorNeedLicense">
                            <div>
                                <el-upload :on-remove="onLicenseRemove" :before-upload="beforeAvatarUpload" :on-success="onLicenseSuccess" :file-list="authorEditingInfo.license" :class="{ 'hide-upload': authorEditingInfo.license.length > 0 }" :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`" list-type="picture-card">
                                    <i class="el-icon-camera-solid" />
                                </el-upload>
                                <div class="upload-tip">营业执照及有关证明</div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="button-area">
                    <div class="button-container">
                        <el-button class="cancel-button button" plain @click="authorFormShow = false">取消</el-button>
                        <el-button class="save-button button" @click="authorEditingSave">保存</el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog :visible.sync="showMsg" :show-close="false" :close-on-click-modal="false" class="showMsg" width="20%" center>
            <div class="img-box">
                <img src="../../assets/image/button-top.png" width="100%" alt="" />
                <img class="button-img" src="../../assets/image/button.png" width="50%" alt="" @click="onclickImg()" />
            </div>
        </el-dialog>

        <el-dialog :visible.sync="showActMsg" title="" width="80%" center>
            <div class="width-100 flex-column justify-content-start align-items-center">
                <!-- <el-image src="../../assets/image/rule.png" /> -->
                <img src="../../assets/image/rule.png" width="100%" alt="" />
                <!-- <p v-if="!myApplicationFormflag" class="fs-sml black margin-ts width-100 textAlign-c" style="color: red">签章位置：{{ type === '0' ? '著作权人' : '作者' }}</p>
                <p class="fs-sml black margin-ts width-100 textAlign-c">签章人员：{{ name }}</p>
                <p class="fs-sml black margin-ts width-100 textAlign-c">待签章人身份证：{{ idcard }}</p>
                <p class="fs-sml black margin-ts width-100 textAlign-c">手机端签章：请使用浙里办扫描去签章</p>
                <p class="fs-sml black margin-ts width-100 textAlign-c">网页端签章：{{ url }}</p> -->
            </div>
            <div class="width-100 flex-column justify-content-start align-items-star margin-b margin-r margin-l">
                <div>附件：</div>
                <a class="margin-t" href="https://bqsb.rarb.cn/profile/otherFile/2024年“梅源杯”中华传统节庆礼品版权设计大赛参赛作品类别及要求.docx">1.2024年“梅源杯”中华传统节庆礼品版权设计大赛参赛作品类别及要求(点击查看)</a>
                <a class="margin-b margin-t" href="https://bqsb.rarb.cn/profile/otherFile/2024年“梅源杯”中华传统节庆礼品版权设计大赛参赛报名表.docx">2.2024年“梅源杯”中华传统节庆礼品版权设计大赛参赛报名表(点击查看)</a>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showActMsg = false">我知道了</el-button>
            </span>
        </el-dialog>

        <div v-if="applicationFormFlag" class="br-rol" style="position: fixed; bottom: 30%; right: 1%; z-index: 1" @click="ruleDownload">
            <img :src="require('../../assets/image/ruleD.png')" width="80px" height="80px" fit="contain" />
        </div>
    </div>
</template>
<script>
import pdf from 'vue-pdf';
import top from '../../components/top.vue';
import { getDict } from '@/api/dict';
import { getValueByText, validatorIdCard, getBirthByIdCard, getGenderByIdCard } from '@/util/helper';
import config from '@/config/defult';
import moment from 'moment';
import listEditMixin from '@/views/iterateRegister/listEditMixin';
import imagesMixin from '@/views/iterateRegister/imagesMixin';
import { postCopyright, getCopyrightInfo, postSignature, putApplicationForm, postApplicationFormSignature } from '@/api/copyright';

export default {
    name: 'IterateRegister',
    components: { top, pdf },
    mixins: [listEditMixin, imagesMixin],
    data() {
        return {
            loading: false,
            PROJECT_BASE_URL: config.PROJECT_BASE_URL,
            copyrightId: +this.$route.query.copyrightId || 0,
            editable: !(this.$route.query.edit === 'false'),
            ownerId: null,
            authorId: null,
            showActMsg: false,
            showMsg: !+this.$route.query.copyrightId,
            formData: {
                /**
                 * 选择个人作品和法人作品的时候，作者信息不需要，著作权人必须只选择一个
                 * 只有职务作品需要归属年限
                 * 已发表的时候才需要填写首次发表日期
                 * 所有具体选单（radio），以第一个为默认
                 */
                id: null, // copyright id 更新操作时带上
                title: '', // 作品名称 1 < len < 50
                ownerShipWayNo: null, // 归属方式编号
                copyRightTypeNo: null, // 作品类别编号
                vestingYears: '', // 归属年限 例："2023"
                creationTypeNo: null, // 作品创作性质编号
                createDate: null, // 创作/制作完成日期（yyyy-MM-DD）
                isPublic: null, // 发表状态
                firstPublicDate: null, // 首次发表日期（yyyy-MM-DD）
                rightOptions: [], // 权利拥有状况（','分割） 例如：(发表权,署名权,其他)
                rightWayNo: null, // 权利取得方式编号
                instruction: '', // 作品简介及创作 30 < len < 2000
                experience: '', // 创作经过及意图 30 < len < 2000
                name: '', // 姓名或单位名称
                phone: '', // 联系方式
                address: '', // 详细地址
                mailer: '', // 联系人
                mobile: '', // 联系人联系方式
                email: '', // 联系人邮箱
                ownersInfo: [
                    // {
                    //     id: null, // 著作权人 id
                    //     isPrint: null, // 是否具名（0：不具名，1：具名）
                    // },
                ],
                authorsInfo: [
                    // {
                    //     id: null, // 作者 id
                    //     isPrint: null, // 是否具名（0：不具名，1：具名）
                    // },
                ],
                samples: [
                    // 作品样本
                    // number // 图片上传 id
                ],
                ancillaryMaterials: [
                    // 辅助材料
                    // number // 图片上传 id
                ],
                signName: '',
                idcard: '',
                company: '',
                signPhone: '',
                openingBank: '',
                bankAccount: '',
                accountName: '',
                signType: ''
            },
            applicationForm: {
                signName: '',
                idcard: '',
                company: '',
                phone: '',
                openingBank: '',
                bankAccount: '',
                accountName: '',
                signType: ''
            },
            copyRightTypeDict: [],
            ownerShipWayDict: [],
            creationTypeDict: [],
            isPublicDict: [],
            rightWayDict: [],
            rightOptionDict: [],
            copyrightActivityTypeDict: [],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            copyrightActivityType: null,
            signTypeDict: [],
            applicationFormFlag: false,
            copyrightActivityName: ''
        };
    },
    computed: {
        isCreating() {
            return this.copyrightId === 0;
        },
        notNeedAuthorInfo() {
            if (this.formData.ownerShipWayNo) {
                return this.formData.ownerShipWayNo === getValueByText(this.ownerShipWayDict, '个人作品') || this.formData.ownerShipWayNo === getValueByText(this.ownerShipWayDict, '法人作品');
            } else {
                return false;
            }
        },
        needVestingYears() {
            return this.formData.ownerShipWayNo === getValueByText(this.ownerShipWayDict, '职务作品');
        },
        needFirstPublicDate() {
            return this.formData.isPublic === getValueByText(this.isPublicDict, '已发表');
        },
        selectAllRightOptions: {
            get() {
                return this.formData.rightOptions.length === this.rightOptionDict.length;
            },
            set(value) {
                if (value) {
                    this.formData.rightOptions = this.rightOptionDict.map(({ text }) => text);
                } else {
                    this.formData.rightOptions = [];
                }
            }
        },
        rules() {
            const checkMail = (_rule, value, callback) => {
                const reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
                if (!value) return callback(new Error('请输入邮箱地址'));
                if (!reg.test(value)) return callback(new Error('请输入正确的邮箱地址'));
                callback();
            };
            const checkIdcard = (_rule, value, callback) => {
                if (!value) return callback(new Error('请输入身份证号码'));
                if (!validatorIdCard(value)) return callback(new Error('请输入正确的身份证号码'));
                callback();
            };
            const checkPhone = (_rule, value, callback) => {
                const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                const regOldPhone = /^\d{3}-\d{8}$|^\d{4}-\d{7}$|^\d{4}-\d{8}$/;
                if (!value) return callback(new Error('请输入手机号码'));
                if (!reg.test(value) && !regOldPhone.test(value)) return callback(new Error('请输入正确的手机号码'));
                callback();
            };
            return {
                copyRightTypeNo: [{ required: true }],
                title: [
                    { required: true, message: '请输入作品名称，格式为作品名称+作品类目，例如《中国梦》平面设计。', trigger: 'blur' },
                    { max: 50, message: '少于50个字符', trigger: 'blur' }
                ],
                createDate: [{ required: true, message: '请选择创作/制作完成日期', trigger: 'blur' }],
                creationTypeNo: [{ required: true }],
                ownerShipWayNo: [{ required: true }],
                isPublic: [{ required: true }],
                rightWayNo: [{ required: true }],
                firstPublicDate: [{ required: true, message: '请选择创作/制作完成日期', trigger: 'blur' }],
                vestingYears: [{ required: true }],
                rightOptions: [{ required: true, message: ' ' }],
                samples: [{ required: true, message: '请上传作品样本，如上传视频：作品样本上传视频截图，再将视频源文件发送至2648937849@qq.com，谢谢配合。', trigger: 'blur' }],
                instruction: [
                    { required: true, message: '请上传作品简介及创作', trigger: 'blur' },
                    { message: '30字以上', trigger: 'blur', min: 30 }
                ],
                experience: [
                    { required: true, message: '请上传创作经过及意图', trigger: 'blur' },
                    { message: '30字以上', trigger: 'blur', min: 30 }
                ],
                name: [{ required: true, message: '请输入姓名或单位名称', trigger: 'blur' }],
                phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
                address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
                mailer: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                mobile: [{ required: true, trigger: 'blur', validator: checkPhone }],
                email: [{ required: true, trigger: 'blur', validator: checkMail }],
                signName: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
                idcard: [{ required: true, trigger: 'blur', validator: checkIdcard }],
                company: [{ required: true, message: '请输入单位', trigger: 'blur' }],
                openingBank: [{ required: true, message: '请输入银行开户行', trigger: 'blur' }],
                bankAccount: [{ required: true, message: '请输入银行卡账号', trigger: 'blur' }],
                accountName: [{ required: true, message: '请输入账号姓名', trigger: 'blur' }],
                signType: [{ required: true }]
            };
        },
        samplesTip() {
            let content;
            if (this.applicationFormFlag) {
                content = '平面设计属于美术类目，作品提交JPEG格式；产品整体设计属于产品设计图，作品提交PDF格式，PDF文件里面包括产品设计图、产品多视图、尺寸图、产品文字介绍等产品相关数据和图片。如作品过大，请上传符合大小的缩略图，并将原文件发送至2648937849@qq.com中，邮件标题写明作品名称。';
            } else {
                if (this.formData.copyRightTypeNo === 13) {
                    console.log(13);
                    content = '作品为产品设计，需要提供pdf文件，文件里包括产品效果图、实物图、尺寸图、多角度视图和产品介绍。\\a如上传视频：作品样本上传视频截图，再将视频源文件发送至2648937849@qq.com，谢谢配合。';
                } else {
                    console.log('other');
                    content = '如上传视频：作品样本上传视频截图，再将视频源文件发送至2648937849@qq.com，谢谢配合。';
                }
            }
            return {
                '--samples-tip': `\x22${content}\x22`
            };
        }
    },
    async mounted() {
        // 获取字典
        [this.copyRightTypeDict, this.ownerShipWayDict, this.creationTypeDict, this.isPublicDict, this.rightWayDict, this.rightOptionDict, this.copyrightActivityTypeDict] = await Promise.all([
            getDict('bqsb_copyright_type'), // 获取类别字典
            getDict('bqsb_copyright_owner_ship_way_type'), // 获取归属方式字典
            getDict('bqsb_copyright_creation_type'), // 获取创作性质字典
            getDict('bqsb_copyright_is_public'), // 获取发表状态字典
            getDict('bqsb_copyright_right_way_type'), //  获取权利取得方式字典
            getDict('bqsb_copyright_right_state_type'), // 获取权利拥有状况字典
            getDict('bqsb_copyright_activity_type') // 获取活动类型字典
        ]);
        this.signTypeDict = [
            {
                text: '中华传统节庆礼品 设计类',
                value: '0'
            },
            {
                text: '瑞安伴手礼 设计类',
                value: '1'
            },
            {
                text: '梅源特色工艺礼品 设计类',
                value: '2'
            },
            {
                text: '其他',
                value: '3'
            }
        ];
        if (this.$route.query.copyrightActivity) {
            this.applicationFormFlag = true;
            this.copyrightActivityName = this.$route.query.copyrightActivity;
        }
        // 获取著作权人和作者列表
        await Promise.all([this.getOwnerList(), this.getAuthorList()]);
        // 设置默认值
        if (!this.isCreating) {
            const res = await getCopyrightInfo(this.copyrightId);
            this.formData.id = res.data.copyrightInfo.id;
            this.formData.copyrightActivityType = res.data.copyrightInfo.copyrightActivityType;
            this.formData.title = res.data.copyrightInfo.title;
            this.formData.ownerShipWayNo = res.data.copyrightInfo.ownerShipWayNo;
            this.formData.copyRightTypeNo = res.data.copyrightInfo.copyRightTypeNo;
            this.formData.vestingYears = res.data.copyrightInfo.vestingYears;
            if (res.data.copyrightDetailInfo) {
                this.formData.creationTypeNo = res.data.copyrightDetailInfo.creationTypeNo;
                this.formData.createDate = res.data.copyrightDetailInfo.createDate ? new Date(res.data.copyrightDetailInfo.createDate) : null;
                this.formData.isPublic = res.data.copyrightDetailInfo.isPublic;
                this.formData.firstPublicDate = res.data.copyrightDetailInfo.firstPublicDate ? new Date(res.data.copyrightDetailInfo.firstPublicDate) : null;
                if (res.data.copyrightDetailInfo.rightOptions === '') {
                    this.formData.rightOptions = [];
                } else {
                    this.formData.rightOptions = res.data.copyrightDetailInfo.rightOptions.split(',');
                }
                this.formData.rightWayNo = res.data.copyrightDetailInfo.rightWayNo;
                this.formData.instruction = res.data.copyrightDetailInfo.instruction;
                this.formData.experience = res.data.copyrightDetailInfo.experience;
            }
            if (res.data.copyrightContact) {
                this.formData.name = res.data.copyrightContact.name;
                this.formData.phone = res.data.copyrightContact.phone;
                this.formData.address = res.data.copyrightContact.address;
                this.formData.mailer = res.data.copyrightContact.mailer;
                this.formData.mobile = res.data.copyrightContact.mobile;
                this.formData.email = res.data.copyrightContact.email;
            }
            if (res.data.copyrightInfo && res.data.copyrightInfo.copyrightActivityType) {
                this.applicationFormFlag = true;
                this.formData.copyrightActivityType = res.data.copyrightInfo.copyrightActivityType;
            }
            if (res.data.myApplicationForm) {
                this.formData.signName = res.data.myApplicationForm.signName;
                this.formData.idcard = res.data.myApplicationForm.idcard;
                this.formData.company = res.data.myApplicationForm.company;
                this.formData.signPhone = res.data.myApplicationForm.phone;
                this.formData.openingBank = res.data.myApplicationForm.openingBank;
                this.formData.bankAccount = res.data.myApplicationForm.bankAccount;
                this.formData.accountName = res.data.myApplicationForm.accountName;
                this.formData.signType = res.data.myApplicationForm.signType;
            }
            res.data.ownersInfo.sort((a, b) => {
                return a.ownerId - b.ownerId;
            });
            let formOwnerIndex = 0;
            let resOwnerIndex = 0;
            while (formOwnerIndex < this.ownerList.length && resOwnerIndex < res.data.ownersInfo.length) {
                const formOwner = this.ownerList[formOwnerIndex];
                const resOwner = res.data.ownersInfo[resOwnerIndex];
                console.log(formOwner.id, resOwner.ownerId);
                if (formOwner.id === resOwner.ownerId) {
                    this.$refs.ownerTable.toggleRowSelection(formOwner, true);
                    formOwnerIndex++;
                    resOwnerIndex++;
                } else if (formOwner.id < resOwner.id) {
                    formOwnerIndex++;
                } else {
                    resOwnerIndex++;
                }
            }
            res.data.authorsInfo.sort((a, b) => {
                return a.ownerId - b.ownerId;
            });
            let formAuthorIndex = 0;
            let resAuthorIndex = 0;
            while (formAuthorIndex < this.authorList.length && resAuthorIndex < res.data.authorsInfo.length) {
                const formAuthor = this.authorList[formAuthorIndex];
                const resAuthor = res.data.authorsInfo[resAuthorIndex];
                if (formAuthor.id === resAuthor.ownerId) {
                    this.$refs.authorTable.toggleRowSelection(formAuthor, true);
                    formAuthor.isPrint = resAuthor.isPrint === '1';
                    formAuthorIndex++;
                    resAuthorIndex++;
                } else if (formAuthorIndex.id < resAuthor.ownerId) {
                    formAuthorIndex++;
                } else {
                    resAuthorIndex++;
                }
            }
            res.data.copyrightFile.forEach((data) => {
                let realUrl;
                if (data.targetFileUrl.startsWith('http')) {
                    realUrl = data.targetFileUrl;
                } else {
                    realUrl = this.PROJECT_BASE_URL + data.targetFileUrl;
                }
                if (/.pdf/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2023/05/04/pdf.jpg`;
                if (/.dwg/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2024/03/25/dwg.jpg`;
                if (/.doc/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                if (/.docx/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                if (data.copyrightFileTypeNo === '4') {
                    this.formData.samples.push({
                        name: data.fileName,
                        response: { data },
                        url: realUrl
                    });
                } else if (data.copyrightFileTypeNo === '6') {
                    this.formData.ancillaryMaterials.push({
                        name: data.fileName,
                        response: { data },
                        url: realUrl
                    });
                }
            });
        } else {
            // 无现有数据，设置默认值
            if (this.copyRightTypeDict.length > 0) {
                this.formData.copyRightTypeNo = this.copyRightTypeDict[0].value;
            }
            if (this.ownerShipWayDict.length > 0) {
                this.formData.ownerShipWayNo = this.ownerShipWayDict[0].value;
            }
            if (this.creationTypeDict.length > 0) {
                this.formData.creationTypeNo = this.creationTypeDict[0].value;
            }
            if (this.isPublicDict.length > 0) {
                this.formData.isPublic = this.isPublicDict[0].value;
            }
            if (this.rightWayDict.length > 0) {
                this.formData.rightWayNo = this.rightWayDict[0].value;
            }
        }
        if (this.applicationFormFlag) {
            if (!this.formData.copyrightActivityType) {
                const copyrightActivityValue = getValueByText(this.copyrightActivityTypeDict, this.copyrightActivityName);
                if (copyrightActivityValue && copyrightActivityValue !== '') {
                    this.formData.copyrightActivityType = copyrightActivityValue;
                }
            }
            this.applicationFormFlag = true;
            this.showActMsg = true;
            this.copyRightTypeDict = this.copyRightTypeDict.filter((row) => {
                if (row.text === '美术') return true;
                if (row.text === '工程设计图、产品设计图') {
                    row.text = '产品设计图';
                    return true;
                }
                if (row.text === '其他') return true;
                return false;
            });
            if (this.isCreating) {
                this.formData.copyRightTypeNo = this.copyRightTypeDict[0].value;
            }
        }
        // console.log('this.copyrightActivityTypeDict:', this.copyrightActivityTypeDict);
    },
    created() {},
    methods: {
        async handleSave() {
            this.loading = true;
            // const dataForm = this.$refs.dataForm;
            const samples = this.formData.samples.map((file) => file.response.data.id);
            const ancillaryMaterials = this.formData.ancillaryMaterials.map((file) => file.response.data.id);
            const body = {
                ...this.formData,
                ownersInfo: this.ownerSelection.map((owner) => ({ ownerId: owner.id, isPrint: '1' })),
                authorsInfo: this.notNeedAuthorInfo ? [] : this.authorSelection.map((author) => ({ ownerId: author.id, isPrint: author.isPrint ? '1' : '0' })),
                vestingYears: this.needVestingYears ? this.formData.vestingYears : '',
                firstPublicDate: this.needFirstPublicDate ? moment(this.formData.firstPublicDate).format('yyyy-MM-DD') : null,
                rightOptions: this.formData.rightOptions.join(','),
                createDate: this.formData.createDate ? moment(this.formData.createDate).format('yyyy-MM-DD') : null,
                files: samples.concat(ancillaryMaterials),
                samples: undefined,
                ancillaryMaterials: undefined
            };
            // console.log(body);
            const res = await postCopyright(body);
            this.copyrightId = res.data.copyrightInfo.id;
            this.formData.id = this.copyrightId;
            if (this.applicationFormFlag) {
                this.applicationForm.signName = this.formData.signName;
                this.applicationForm.idcard = this.formData.idcard;
                this.applicationForm.gender = getGenderByIdCard(this.formData.idcard);
                const birthDate = new Date(getBirthByIdCard(this.formData.idcard));
                this.applicationForm.birthDate = `${birthDate.getFullYear()}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
                this.applicationForm.company = this.formData.company;
                this.applicationForm.phone = this.formData.signPhone;
                this.applicationForm.openingBank = this.formData.openingBank;
                this.applicationForm.bankAccount = this.formData.bankAccount;
                this.applicationForm.accountName = this.formData.accountName;
                this.applicationForm.signType = this.formData.signType;
                this.applicationForm.copyrightId = this.copyrightId;
                // 保存报名表
                await putApplicationForm(this.copyrightId, this.applicationForm);
            }
            this.loading = false;
        },
        async handleCommit() {
            const dataForm = this.$refs.dataForm;
            // 校验著作权人和作者
            const msg = this.checkOwnerAuthorLimit();
            if (msg !== '') {
                this.$alert(msg, '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
            } else {
                await dataForm.validate().then(
                    async () => {
                        // 保存版权信息
                        await this.handleSave();
                        if (this.applicationFormFlag) {
                            // 保存报名表
                            await postApplicationFormSignature(this.copyrightId);
                        }
                        this.loading = true;
                        const msg = await postSignature(this.copyrightId);
                        this.loading = false;
                        if (msg.code !== 200) {
                            this.$alert(msg.msg, '提示', {
                                confirmButtonText: '确定',
                                callback: () => {}
                            });
                        } else {
                            this.$router.push({ path: '/signature', query: { copyrightId: this.copyrightId } });
                        }
                    },
                    (_err) => {
                        // TODO
                    }
                );
            }
        },
        ruleDownload() {
            window.location.href = 'https://bqsb.rarb.cn/profile/otherFile/关于举办2024年“梅源杯”中华传统节庆礼品版权设计大赛的通知.doc';
        },
        onSampleSuccess(res, file, fileList) {
            if (res.code !== 200) {
                this.$message.error('图片上传错误');
            } else {
                fileList.forEach((file) => {
                    if (/.pdf/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2023/05/04/pdf.jpg`;
                    if (/.dwg/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/03/25/dwg.jpg`;
                    if (/.doc/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                    if (/.docx/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                });
                this.formData.samples = fileList;
            }
        },
        onSampleRemove(file, fileList) {
            this.formData.samples = fileList;
        },
        onAncillaryMaterialsSuccess(res, file, fileList) {
            if (res.code !== 200) {
                this.$message.error('图片上传错误');
            } else {
                fileList.forEach((file) => {
                    if (/.pdf/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2023/05/04/pdf.jpg`;
                    if (/.dwg/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/03/25/dwg.jpg`;
                    if (/.doc/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                    if (/.docx/.test(file.name)) file.url = this.PROJECT_BASE_URL + `/profile/bqsb/2024/08/15/word.jpg`;
                });
                this.formData.ancillaryMaterials = fileList;
            }
        },
        onAncillaryMaterialsRemove(file, fileList) {
            this.formData.ancillaryMaterials = fileList;
        },
        beforeImageUpload(file) {
            const fileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/x-png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            console.log('file.type:', file);
            if (/#/.test(file.name)) {
                this.$message.error('图片名称不可包含#');
            }
            const fileTypeCheck = !/.dwg/.test(file.name) && !fileType.includes(file.type);
            if (!/.dwg/.test(file.name)) {
                if (!fileType.includes(file.type)) {
                    this.$message.error('上传图片只能是 JPG/JPEG/PNG/PDF 格式!');
                }
            }
            const isLt2M = file.size / 1024 / 1024 < 3.5;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 3.5MB!');
            }
            return isLt2M && !fileTypeCheck && !/#/.test(file.name);
        },
        beforeAvatarUpload(file) {
            const fileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/x-png'];
            if (/#/.test(file.name)) {
                this.$message.error('图片名称不可包含#');
            }
            if (!fileType.includes(file.type)) {
                this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
            }
            const isLt2M = file.size / 1024 / 1024 < 3.5;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 3.5MB!');
            }
            return isLt2M && fileType.includes(file.type) && !/#/.test(file.name);
        },
        checkOwnerAuthorLimit() {
            if (!this.ownerSelection || this.ownerSelection.length === 0) return '著作权人必须填写';
            if (this.formData.ownerShipWayNo === 1) {
                if (this.ownerSelection.length > 1) return '著作权人只能一个';
            } else if (this.formData.ownerShipWayNo === 2) {
                if (this.ownerSelection.length < 2) return '著作权人必须两人以上';
                if (!this.authorSelection || this.authorSelection.length === 0) return '作者必须填写';
            } else if (this.formData.ownerShipWayNo === 3) {
                if (this.ownerSelection.findIndex((row) => row.ownerTypeNo !== 2) > -1) return '著作权人必须为法人';
                if (!this.authorSelection || this.authorSelection.length === 0) return '作者必须填写';
                if (this.authorSelection.findIndex((row) => row.ownerTypeNo !== 1) > -1) return '作者必须为自然人';
            } else if (this.formData.ownerShipWayNo === 4) {
                if (this.ownerSelection.length > 1) return '著作权人只能一个';
                if (this.ownerSelection.findIndex((row) => row.ownerTypeNo !== 2) > -1) return '著作权人必须为法人';
            } else if (this.formData.ownerShipWayNo === 5) {
                if (!this.authorSelection || this.authorSelection.length === 0) return '作者必须填写';
            } else {
                if (!this.authorSelection || this.authorSelection.length === 0) return '作者必须填写';
            }
            return '';
        },
        // handlePictureCardPreview(files) {
        //     console.log('files:', files);
        //     const file = files.raw;
        //     if (file.type === 'application/pdf') {
        //         this.dialogVisibles = true;
        //         // this.fileUrl = pdf.createLoadingTask('http://file.dakawengu.com/file/2018-05-29/20180527-tianfeng.pdf')
        //         this.fileUrl = pdf.createLoadingTask(files.url);
        //         console.log('this.fileUrl:', this.fileUrl);
        //         // window.open(this.fileUrl)
        //         // this.dialogVisible = false
        //         // this.message.error('请上传正确的文件格式！')
        //     }
        // },
        // handleRemove(files) {
        //     console.log('files:', files);
        // }
        copyRightTypeChange(value) {
            if (this.applicationFormFlag) {
                const text = '平面设计属于美术类目，作品提交JPEG格式；产品整体设计属于产品设计图，作品提交PDF格式，PDF文件里面包括产品设计图、产品多视图、尺寸图、产品文字介绍等产品相关数据和图片。如作品过大，请上传符合大小的缩略图，并将原文件发送至2648937849@qq.com中，邮件标题写明作品名称。';
                document.documentElement.style.setProperty('@content', text);
            } else {
                const text = '如上传视频：作品样本上传视频截图，再将视频源文件发送至2648937849@qq.com，谢谢配合。';
                const text2 = '作品为产品设计，需要提供pdf文件，文件里包括产品效果图、实物图、尺寸图、多角度视图和产品介绍。\A如上传视频：作品样本上传视频截图，再将视频源文件发送至2648937849@qq.com，谢谢配合。';
                if (value === 13) {
                    document.documentElement.style.setProperty('@content', text2);
                } else {
                    document.documentElement.style.setProperty('@content', text);
                }
            }
        },
        onclickImg() {
            this.showMsg = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/views/iterateRegister/iterateRegister.less';
@import '~@/views/iterateRegister/iterateRegisterPublic.less';
@import '~@/views/iterateRegister/editWindow.less';
</style>

<style lang="less">
.el-cascader-menu {
    height: auto;
}
.showMsg {
    margin-top: 25vh;
    .el-dialog__header {
        padding: 0 !important;
    }
    .el-dialog__body {
        padding: 0 !important;
    }
    .img-box {
        .button-img {
            position: absolute;
            top: 7rem;
            left: 42%;
        }
    }
}
</style>
