import request from '@/util/request';

export function getOwnerInfoList() {
    return request({
        url: '/bqsb/ownerInfo/owner/list',
        method: 'get'
    });
}

export function getAuthorInfoList() {
    return request({
        url: '/bqsb/ownerInfo/author/list',
        method: 'get'
    });
}

export function getOwnerInfoById(id) {
    return request({
        url: `/bqsb/ownerInfo/${id}`,
        method: 'get'
    });
}

export function getAuthorInfoById(id) {
    return request({
        url: `/bqsb/ownerInfo/${id}`,
        method: 'get'
    });
}

export function postOwnerInfo(data) {
    return request({
        url: '/bqsb/ownerInfo',
        method: 'post',
        data
    });
}

export function postAuthorInfo(data) {
    return request({
        url: '/bqsb/ownerInfo',
        method: 'post',
        data
    });
}

export function putOwnerInfo(data) {
    return request({
        url: '/bqsb/ownerInfo',
        method: 'put',
        data
    });
}

export function deleteOwnerInfoById(id) {
    return request({
        url: `/bqsb/ownerInfo/${id}`,
        method: 'delete'
    });
}

export function deleteAuthorInfoById(id) {
    return request({
        url: `/bqsb/ownerInfo/${id}`,
        method: 'delete'
    });
}
