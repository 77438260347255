import titleIcon from '@/assets/image/1548.png';
import addNewMemberIcon from '@/assets/image/1170.png';

export default {
    data() {
        return {
            titleIcon,
            addNewMemberIcon
        };
    }
};
