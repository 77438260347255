import request from '@/util/request';

export function getCopyrightInfo(id) {
    return request({
        url: `/bqsb/copyright/${id}`,
        method: 'get'
    });
}

export function putCopyrightWorkInfo(data) {
    return request({
        url: '/bqsb/copyright/workInfo',
        method: 'put',
        data
    });
}

export function putCopyrightOwner(id, ownersInfo) {
    return request({
        url: `/bqsb/copyright/${id}/owner`,
        method: 'put',
        data: {
            ownersInfo
        }
    });
}

export function putCopyrightBaseWorkInfo(id, data) {
    return request({
        url: `/bqsb/copyright/${id}/baseWorkInfo`,
        method: 'put',
        data
    });
}

export function putCopyrightContact(id, data) {
    return request({
        url: `/bqsb/copyright/${id}/contact`,
        method: 'put',
        data
    });
}

export function postCopyright(data) {
    return request({
        url: `/bqsb/copyright`,
        method: 'post',
        data
    });
}

export function postSignature(id) {
    return request({
        url: `/bqsb/copyright/${id}/signature`,
        method: 'post'
    });
}

export function getSignatureUrl(id) {
    return request({
        url: `/bqsb/copyright/${id}/signature/url`,
        method: 'get'
    });
}

export function getSignatureList(id) {
    return request({
        url: `/bqsb/copyright/${id}/signature/list`,
        method: 'get'
    });
}

export function getNearestCopyright() {
    return request({
        url: `/bqsb/copyright/nearest`,
        method: 'get'
    });
}

export async function getCopyrightSuccessAll() {
    const data = await request({
        url: `/bqsb/copyright/success/all`,
        method: 'get'
    });
    return data.data;
}

export function getCopyrightStatistics() {
    return request({
        url: `/bqsb/copyright/statistics`,
        method: 'get'
    });
}

export function getCopyrightByStatus(status, pageNum, pageSize) {
    return request({
        url: `/bqsb/copyright/status/${status}`,
        method: 'get',
        params: {
            pageNum,
            pageSize
        }
    });
}

export function getCopyrightBriefly(params) {
    return request({
        url: `/bqsb/copyright/briefly`,
        method: 'get',
        params
    });
}

export function putApplicationForm(id, data) {
    return request({
        url: `/bqsb/copyright/${id}/applicationForm`,
        method: 'put',
        data
    });
}
// 开始报名表签字
export function postApplicationFormSignature(id) {
    return request({
        url: `/bqsb/copyright/applicationForm/${id}/signature`,
        method: 'post'
    });
}
// 获取报名表签字地址
export function getApplicationFormSignatureUrl(id) {
    return request({
        url: `/bqsb/copyright/applicationForm/${id}/signature/url`,
        method: 'get'
    });
}
// 获取支付地址
export function getCopyrightPayUrl(id) {
    return request({
        url: `/bqsb/copyright/${id}/pay/url`,
        method: 'get'
    });
}
