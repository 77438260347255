<template>
    <div class="top-box box-shadow bg-white width-100 flex-row justify-content-spaceBetween align-items-center">
        <div class="width-50 flex-row justify-content-start align-items-center">
            <img src="../assets/image/1694.png" height="40" alt="" />
            <div class="position-relative width-70 flex-row justify-content-start align-items-center">
                <p :class="active == '1' ? 'blue' : 'black'" class="fs-mid-big width-20 margin-l textAlign-c pointer" @click="$router.push('/index')">首页</p>
                <p :class="active == '2' ? 'blue' : 'black'" class="fs-mid-big width-20 margin-l textAlign-c pointer" @click="$router.push('/register')">作品登记</p>
                <span :class="active == '1' ? 'block1' : 'block2'"></span>
            </div>
        </div>
        <div class="width-50 flex-row justify-content-end align-items-center">
            <el-image :src="url" class="avatar margin-r" fit="cover"></el-image>
            <p class="fs-big black margin-r">你好，{{ name }}</p>
            <p class="blue fs-big pointer" @click="outLogin()">[ 退出登录 ]</p>
        </div>
    </div>
</template>
<script>
import { getCopyrightStatistics } from '@/api/copyright';

export default {
    name: 'Top',
    props: {
        active: {
            type: String,
            default: '1'
        }
    },
    data() {
        return {
            name: '',
            mobile: '',
            url: ''
        };
    },
    async created() {
        const statistics = await getCopyrightStatistics();
        this.name = statistics.data.name;
        this.mobile = statistics.data.mobile;
        this.url = statistics.data.avatarUrl || 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg';
    },
    methods: {
        outLogin() {
            localStorage.removeItem('bqsb_login');
            this.$router.push({ path: '/' });
        }
    }
};
</script>

<style lang="less" scoped>
.top-box {
    padding: 20px 5%;
    position: sticky;
    top: 0;
    z-index: 9;
    .block1 {
        width: 30px;
        height: 4px;
        border-radius: 5px;
        display: inline-block;
        background: #007aff;
        position: absolute;
        left: 9%;
        bottom: -10px;
        transition: 0.5s;
    }
    .block2 {
        width: 30px;
        height: 4px;
        border-radius: 5px;
        display: inline-block;
        background: #007aff;
        position: absolute;
        left: 30.5%;
        bottom: -10px;
        transition: 0.5s;
    }
    .avatar {
        width: 44px;
        height: 44px;
        border-radius: 50%;
    }
}
</style>
