import { deleteOwnerInfoById, getAuthorInfoList, getOwnerInfoList } from '@/api/ownerInfo';
import config from '@/config/defult';
import { postOwnerInfo, getOwnerInfoById, postAuthorInfo, getAuthorInfoById } from '@/api/ownerInfo';
import { getSigleArea } from '@/api/util';
import _ from 'lodash';

async function getOwnerTypeDict() {
    return {
        data: [
            {
                text: '自然人',
                value: '1'
            },
            {
                text: '法人或其他组织',
                value: '2'
            }
        ]
    };
}

export default {
    data() {
        return {
            ownerTypeDict: [],
            ownerList: [],
            ownerListLoading: false,
            ownerSelection: [],
            ownerFormShow: false,
            ownerEditingInfo: {
                id: null,
                ownerTypeNo: null,
                ownerName: '',
                cardNo: '',
                mobile: '',
                regionsNowNo: null,
                regionsNowNoList: null,
                signature: '',
                idCardAvatar: [],
                idCardBack: [],
                license: [],
                copyrightFiles: []
            },
            authorList: [],
            authorListLoading: false,
            authorSelection: [],
            authorFormShow: false,
            authorEditingInfo: {
                id: null,
                ownerTypeNo: null,
                ownerName: '',
                cardNo: '',
                mobile: '',
                signature: '',
                idCardAvatar: [],
                idCardBack: [],
                license: [],
                copyrightFiles: []
            },
            areaOptions: {
                lazy: true,
                async lazyLoad(node, resolve) {
                    const { level, value } = node;
                    let area = [];
                    if (Number(level) === 0) {
                        area = await getSigleArea(0);
                    } else {
                        area = await getSigleArea(value);
                    }
                    resolve(
                        area.map((row) => {
                            return {
                                value: row.value,
                                label: row.text,
                                leaf: level >= 2
                            };
                        })
                    );
                }
            }
        };
    },
    computed: {
        ownerNeedIdCard() {
            return this.ownerEditingInfo.ownerTypeNo === '1';
        },
        ownerNeedLicense() {
            return this.ownerEditingInfo.ownerTypeNo === '2';
        },
        authorNeedIdCard() {
            return this.authorEditingInfo.ownerTypeNo === '1';
        },
        authorNeedLicense() {
            return this.authorEditingInfo.ownerTypeNo === '2';
        },
        ownerFormConfig() {
            if (this.ownerNeedIdCard) {
                return {
                    ownerNameLabel: '姓名',
                    ownerCardNoLabel: '证件号',
                    copyrightFilesLabel: '居民身份证上传',
                    cardTypeNo: 1
                };
            } else if (this.ownerNeedLicense) {
                return {
                    ownerNameLabel: '企业名称',
                    ownerCardNoLabel: '统一社会信用代码',
                    copyrightFilesLabel: '营业执照上传',
                    cardTypeNo: 11
                };
            } else {
                return {};
            }
        },
        authorFormConfig() {
            if (this.authorNeedIdCard) {
                return {
                    authorNameLabel: '姓名',
                    authorCardNoLabel: '证件号',
                    copyrightFilesLabel: '居民身份证上传',
                    cardTypeNo: 1
                };
            } else if (this.authorNeedLicense) {
                return {
                    authorNameLabel: '企业名称',
                    authorCardNoLabel: '统一社会信用代码',
                    copyrightFilesLabel: '营业执照上传',
                    cardTypeNo: 11
                };
            } else {
                return {};
            }
        },
        ownerFormRule() {
            return {
                ownerTypeNo: [{ required: true, trigger: 'blur' }],
                ownerName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                cardNo: [{ required: true, message: '自然人请输入居民身份证号，法人或其他社会组织请输入统一社会信用代码', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                regionsNowNoList: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
                // signature: [{ required: true, message: '请输入署名', trigger: 'blur' }],
                idcard: [{ required: true, message: ' ', trigger: 'blur' }]
            };
        },
        authorFormRule() {
            return {
                ownerTypeNo: [{ required: true, trigger: 'blur' }],
                ownerName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                cardNo: [{ required: true, message: '自然人请输入居民身份证号，法人或其他社会组织请输入统一社会信用代码', trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                // regionsNowNo: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
                // signature: [{ required: true, message: '请输入署名', trigger: 'blur' }],
                idcard: [{ required: true, message: ' ', trigger: 'blur' }]
            };
        }
    },
    async mounted() {
        this.ownerTypeDict = await getOwnerTypeDict().then((ownerRes) => ownerRes.data);
        if (this.ownerTypeDict.length > 0) {
            this.ownerEditingInfo.ownerTypeNo = this.ownerTypeDict[0].value;
            this.authorEditingInfo.ownerTypeNo = this.ownerTypeDict[0].value;
        }
    },
    methods: {
        async getOwnerList() {
            this.ownerList = await getOwnerInfoList().then((ownerRes) => {
                ownerRes.data.sort((a, b) => {
                    return a.id - b.id;
                });
                return ownerRes.data;
            });
        },
        async getAuthorList() {
            this.authorList = await getAuthorInfoList()
                .then((ownerRes) => {
                    ownerRes.data.sort((a, b) => {
                        return a.id - b.id;
                    });
                    return ownerRes;
                })
                .then((authorRes) => authorRes.data.map((item) => ({ ...item, isPrint: false })));
        },
        async getOwnerDetail(id) {},
        async deleteOwner(id) {
            try {
                await this.$confirm('确认要删除吗', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
            } catch {
                return;
            }
            try {
                await deleteOwnerInfoById(id);
                await this.$alert('删除成功');
                this.ownerListLoading = true;
                await this.getOwnerList();
            } catch (e) {
                console.error(e);
            } finally {
                this.ownerListLoading = false;
            }
        },
        async deleteAuthor(id) {
            try {
                await this.$confirm('确认要删除吗', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
            } catch {
                return;
            }
            try {
                await deleteOwnerInfoById(id);
                await this.$alert('删除成功');
                this.authorListLoading = true;
                await this.getAuthorList();
            } catch (e) {
                console.error(e);
            } finally {
                this.authorListLoading = false;
            }
        },
        async ownerCreationWindowOpen() {
            this.resetOwnerForm(null);
            this.ownerFormShow = true;
        },
        async ownerUpdateWindowOpen(id) {
            await this.setOwnerForm(id);
            this.ownerFormShow = true;
        },
        async authorCreationWindowOpen() {
            this.resetAuthorForm(null);
            this.authorFormShow = true;
        },
        async authorUpdateWindowOpen(id) {
            await this.setAuthorForm(id);
            this.authorFormShow = true;
        },
        async ownerEditingSave() {
            if (!this.ownerValidate()) {
                this.$alert('请上传身份证明文件！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
            } else {
                await this.$refs.ownerEditingForm.validate().then(
                    async () => {
                        const ownerInfo = this.ownerEditingInfo;
                        // console.log('ownerInfo:', ownerInfo);
                        const copyrightFilesArr = [];
                        if (ownerInfo.ownerTypeNo === '1') {
                            if (ownerInfo.idCardAvatar.length > 0) copyrightFilesArr.push(ownerInfo.idCardAvatar[0].fileId ? ownerInfo.idCardAvatar[0].fileId : ownerInfo.idCardAvatar[0].response.data.id);
                            if (ownerInfo.idCardBack.length > 0) copyrightFilesArr.push(ownerInfo.idCardBack[0].fileId ? ownerInfo.idCardBack[0].fileId : ownerInfo.idCardBack[0].response.data.id);
                        } else {
                            if (ownerInfo.license.length > 0) copyrightFilesArr.push(ownerInfo.license[0].fileId ? ownerInfo.license[0].fileId : ownerInfo.license[0].response.data.id);
                        }
                        const ownerData = {
                            ..._.pick(ownerInfo, ['id', 'cardNo', 'signature', 'ownerName', 'mobile', 'ownerTypeNo']),
                            copyrightOwnerType: 0, // 作者：2，著作权人：0
                            copyrightFiles: copyrightFilesArr.join(','), // 图片的ids（1,2）
                            regionsNowNo: ownerInfo.regionsNowNoList[ownerInfo.regionsNowNoList.length - 1], // 例：330381
                            cardTypeNo: this.ownerFormConfig.cardTypeNo // 身份证号：1，统一信用代码：11
                        };
                        await postOwnerInfo(ownerData);
                        this.ownerFormShow = false;
                        this.ownerListLoading = true;
                        await this.getOwnerList();
                        this.ownerListLoading = false;
                    },
                    (_err) => {
                        // TODO
                    }
                );
            }
        },
        async authorEditingSave() {
            if (!this.authorValidate()) {
                this.$alert('请上传身份证明文件！', '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
            } else {
                await this.$refs.authorEditingForm.validate().then(
                    async () => {
                        const authorInfo = this.authorEditingInfo;
                        const copyrightFilesArr = [];
                        if (authorInfo.ownerTypeNo === '1') {
                            if (authorInfo.idCardAvatar.length > 0) copyrightFilesArr.push(authorInfo.idCardAvatar[0].fileId ? authorInfo.idCardAvatar[0].fileId : authorInfo.idCardAvatar[0].response.data.id);
                            if (authorInfo.idCardBack.length > 0) copyrightFilesArr.push(authorInfo.idCardBack[0].fileId ? authorInfo.idCardBack[0].fileId : authorInfo.idCardBack[0].response.data.id);
                        } else {
                            if (authorInfo.license.length > 0) copyrightFilesArr.push(authorInfo.license[0].fileId ? authorInfo.license[0].fileId : authorInfo.license[0].response.data.id);
                        }
                        const authorData = {
                            ..._.pick(authorInfo, ['id', 'cardNo', 'signature', 'ownerName', 'mobile', 'ownerTypeNo']),
                            copyrightOwnerType: 2, // 作者：2，著作权人：0
                            copyrightFiles: copyrightFilesArr.join(','), // 图片的ids（1,2）
                            cardTypeNo: this.ownerFormConfig.cardTypeNo // 身份证号：1，统一信用代码：11
                        };
                        // console.log('authorData:', authorData);
                        await postAuthorInfo(authorData);
                        this.authorFormShow = false;
                        this.authorListLoading = true;
                        await this.getAuthorList();
                        this.authorListLoading = false;
                    },
                    (_err) => {
                        // TODO
                    }
                );
            }
        },
        ownerValidate() {
            if (this.ownerNeedIdCard) {
                if (this.ownerEditingInfo.idCardAvatar.length === 0 && this.ownerEditingInfo.idCardBack.length === 0) return false;
            }
            if (!this.ownerNeedIdCard && this.ownerEditingInfo.license.length === 0) return false;
            return true;
        },
        authorValidate() {
            if (this.authorNeedIdCard) {
                if (this.authorEditingInfo.idCardAvatar.length === 0 && this.authorEditingInfo.idCardBack.length === 0) return false;
            }
            if (!this.authorNeedIdCard && this.authorEditingInfo.license.length === 0) return false;
            return true;
        },
        async setOwnerForm(id) {
            const data = await getOwnerInfoById(id);
            if (data.code === 200 && data.data) {
                this.resetOwnerForm(data.data);
            } else {
                this.resetOwnerForm(null);
            }
        },
        async setAuthorForm(id) {
            const data = await getAuthorInfoById(id);
            if (data.code === 200 && data.data) {
                this.resetAuthorForm(data.data);
            } else {
                this.resetAuthorForm(null);
            }
        },
        resetOwnerForm(data) {
            const initialForm = {
                id: null,
                ownerTypeNo: this.ownerTypeDict[0].value,
                ownerName: '',
                cardNo: '',
                mobile: '',
                regionsNowNo: [],
                regionsNowNoList: null,
                signature: '',
                idCardAvatar: [],
                idCardBack: [],
                license: [],
                copyrightFiles: []
            };
            if (data) {
                const idCardAvatar = [];
                const idCardBack = [];
                const license = [];
                if (data.cardTypeNo === 1) {
                    if (data.file.length > 0) {
                        idCardAvatar.push({
                            fileId: data.file[0].id,
                            name: data.file[0].fileName,
                            url: config.PROJECT_BASE_URL + data.file[0].targetFileUrl
                        });
                    }
                    if (data.file.length > 1) {
                        idCardBack.push({
                            fileId: data.file[1].id,
                            name: data.file[1].fileName,
                            url: config.PROJECT_BASE_URL + data.file[1].targetFileUrl
                        });
                    }
                } else {
                    if (data.file.length > 0) {
                        license.push({
                            fileId: data.file[0].id,
                            name: data.file[0].fileName,
                            url: config.PROJECT_BASE_URL + data.file[0].targetFileUrl
                        });
                    }
                }
                this.ownerEditingInfo = {
                    ..._.pick(data, ['id', 'ownerName', 'cardNo', 'mobile', 'regionsNowNo', 'signature']),
                    ownerTypeNo: String(data.ownerTypeNo),
                    regionsNowNoList: data.regionsNowNo ? [String(data.regionsNowNo).substring(0, 2), String(data.regionsNowNo).substring(0, 4), String(data.regionsNowNo).substring(0, 6)] : null,
                    idCardAvatar,
                    idCardBack,
                    license
                };
            } else {
                this.ownerEditingInfo = initialForm;
            }
        },
        resetAuthorForm(data) {
            const initialForm = {
                id: null,
                ownerTypeNo: this.ownerTypeDict[0].value,
                ownerName: '',
                cardNo: '',
                mobile: '',
                signature: '',
                idCardAvatar: [],
                idCardBack: [],
                license: [],
                copyrightFiles: []
            };
            if (data) {
                const idCardAvatar = [];
                const idCardBack = [];
                const license = [];
                if (data.cardTypeNo === 1) {
                    if (data.file.length > 0) {
                        idCardAvatar.push({
                            fileId: data.file[0].id,
                            name: data.file[0].fileName,
                            url: config.PROJECT_BASE_URL + data.file[0].targetFileUrl
                        });
                    }
                    if (data.file.length > 1) {
                        idCardBack.push({
                            fileId: data.file[1].id,
                            name: data.file[1].fileName,
                            url: config.PROJECT_BASE_URL + data.file[1].targetFileUrl
                        });
                    }
                } else {
                    if (data.file.length > 0) {
                        license.push({
                            fileId: data.file[0].id,
                            name: data.file[0].fileName,
                            url: config.PROJECT_BASE_URL + data.file[0].targetFileUrl
                        });
                    }
                }
                this.authorEditingInfo = {
                    ..._.pick(data, ['id', 'ownerName', 'cardNo', 'mobile', 'signature']),
                    ownerTypeNo: String(data.ownerTypeNo),
                    idCardAvatar,
                    idCardBack,
                    license
                };
            } else {
                this.authorEditingInfo = initialForm;
            }
        },
        authorSelectChange(val) {
            this.authorSelection = val;
        },
        ownerSelectChange(val) {
            this.ownerSelection = val;
        },
        async ownerSelect(selection, row) {
            if (this.notNeedAuthorInfo) {
                await new Promise((resolve) => setTimeout(resolve, 50));
                const selete = selection.filter((r) => r.id !== row.id);
                selete.forEach((row) => {
                    this.$refs.ownerTable.toggleRowSelection(row);
                });
            }
        },
        onCardAvatarSuccess(res, file, fileList) {
            console.log(fileList);
            this.ownerEditingInfo.idCardAvatar = fileList;
            this.authorEditingInfo.idCardAvatar = fileList;
        },
        onCardAvatarRemove(file, fileList) {
            this.ownerEditingInfo.idCardAvatar = fileList;
            this.authorEditingInfo.idCardAvatar = fileList;
        },
        onCardBackSuccess(res, file, fileList) {
            this.ownerEditingInfo.idCardBack = fileList;
            this.authorEditingInfo.idCardBack = fileList;
        },
        onCardBackRemove(file, fileList) {
            this.ownerEditingInfo.idCardBack = fileList;
            this.authorEditingInfo.idCardBack = fileList;
        },
        onLicenseSuccess(res, file, fileList) {
            this.ownerEditingInfo.license = fileList;
            this.authorEditingInfo.license = fileList;
        },
        onLicenseRemove(file, fileList) {
            this.ownerEditingInfo.license = fileList;
            this.authorEditingInfo.license = fileList;
        }
    }
};
